import { Match, Show, Switch } from "solid-js";
import * as helper from "~/utils/helper_program";

import type { ProgramWrapper } from "~/utils/program_wrapper";

export default function Regulations(props: { wrapper: ProgramWrapper }) {
  return (
    <>
      <ul class="regulation" data-test="regulations">
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_reduced}>
          <li>
            <span>TVA réduite</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_mastered}>
          <li>
            <span>Prix maîtrisés</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_brs}>
          <li>
            <span>Prix BRS</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_bare_ownership}>
          <li>
            <span>Nue-propriété</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_ex}>
          <li>
            <span>LMNP géré</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_inc}>
          <li>
            <span>LMNP</span>
          </li>
        </Show>
        <Switch>
          <Match
            when={
              props.wrapper.program.field_disp_grid_pinel &&
              helper.getPinelZones(props.wrapper)
            }
          >
            <li>
              <span>Pinel, zone {helper.getPinelZones(props.wrapper)}</span>
            </li>
          </Match>
          <Match when={props.wrapper.program.field_disp_grid_pinel}>
            <li>
              <span>Pinel</span>
            </li>
          </Match>
        </Switch>
        <Switch>
          <Match
            when={
              props.wrapper.program.field_disp_grid_pinel_plus &&
              helper.getPinelPlusZones(props.wrapper)
            }
          >
            <li class="pinel-plus">
              <span>
                Pinel +, zone {helper.getPinelPlusZones(props.wrapper)}
              </span>
            </li>
          </Match>
          <Match when={props.wrapper.program.field_disp_grid_pinel_plus}>
            <li class="pinel-plus">
              <span>Pinel +</span>
            </li>
          </Match>
        </Switch>
        <Show when={props.wrapper.program.field_disp_grid_patrimonial}>
          <li>
            <span>Patrimonial</span>
          </li>
        </Show>
      </ul>
    </>
  );
}
